import React from "react";

const IGeekCard = ({ data }) => {
  return (
    <div className="flex h-[285px] w-[300px] flex-col items-center rounded-[18px] border-[1px] border-expressionism-green p-[20px] max_xl:h-auto max_xl:w-[225px] max_xl:p-[10px] max_md:w-[240px]">
      <div className="flex h-[80px] min-h-[80px] w-[80px] items-center justify-center rounded-full bg-[#F2FAF8] max_xl:h-[60px] max_xl:min-h-[60px] max_xl:w-[60px]">
        <img
          draggable="false"
          className="max_xl:w-[28px]"
          src={data.icon}
          alt=""
        />
      </div>
      <div className="mt-[15px] whitespace-nowrap text-center text-LargeSize font-semibold leading-[24px] text-bateau max_xl:mt-[12px] max_xl:text-[13px] max_xl:leading-[16px]">
        {data.title}
      </div>
      <div className="text-her-sub-heading-oak mt-[17px] text-her-sub-heading text-center text-RegularSize leading-[20px] max_xl:mt-[9px] max_xl:text-[11px] max_xl:leading-[16px]">
        {data.desc}
      </div>
    </div>
  );
};

export default IGeekCard;
