import React, { Fragment } from "react";
import DeviderLine from "./DeviderLine";
import { ButtonGradientIcon } from "./utils/Buttons";
import ServiceCard from "./ServiceCard";
import { useNavigate } from "react-router-dom";

const ServiceSectionComp = ({ maxWidth = null, serviceData, icon = null }) => {
  const navigate = useNavigate();
  return (
    <>
      <DeviderLine title={serviceData.DeviderTitle} right="right-[50%]" />

      <div
        className={`m-auto w-full pt-[90px] text-center text-PageTitle font-bold text-duskwood max_md:pt-[60px] ${maxWidth ? maxWidth : "max-w-[258px]"
          } `}
      >
        {serviceData.Heading}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-[80px] mb-[130px] flex w-full items-center max_md:mb-[80px] max_md:flex-col-reverse">
          <div className="w-[70%] max_md:mt-[20px] max_md:flex max_md:w-full max_md:flex-col max_md:items-center max_md:justify-center">
            <div className="max-w-[690px] font-medium text-LargeSize text-her-sub-heading leading-[28px] max_md:text-center max_md:text-SmallSize max_md:leading-[18px]">
              {serviceData.description}
            </div>
            <div className="mt-[40px] w-fit ">
              <ButtonGradientIcon
                title="Consult iGeek"
                background="#2275A1"
                onClick={() => {
                  navigate("/contact-us");
                }}
              />
            </div>
          </div>
          <div className="flex w-[30%] justify-center max_md:w-full">
            <img draggable="false" src={serviceData.Image} alt="" />
          </div>
        </div>

        <DeviderLine title="SERVICES" right="right-[75%]" />

        <div
          className={`mt-[65px] mb-[60px] flex w-full justify-center text-ExLargeSize font-bold text-duskwood max_md:my-[40px] `}
        >
          {serviceData.title}
        </div>
        <div className="flex flex-wrap gap-[15px]  max_md:justify-center">
          <div className="w-[403px] pt-[40px] text-[30px] font-bold leading-[45px] text-duskwood max_md:pt-[0px] max_md:pb-[40px] max_md:text-ExLargeSize max_md:leading-[32px] max_sm:w-[340px] ">
            <div className="max-w-[362px]">
              We provide unparalleled solutions to elevate your enterprise into a renowned brand.
            </div>
          </div>
          {serviceData.cards.map((cardData, index) => (
            <Fragment key={index}>
              <ServiceCard
                title={cardData.title}
                desc={cardData.description}
                background={cardData.background}
                icon={cardData.Image}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceSectionComp;
