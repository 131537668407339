import React from "react";

const TechnologyRoundedCard = ({ icon, name = "" }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex relative h-full w-full group">
        <div className="relative z-10 aspect-square w-[140px] rounded-full border-[2px] border-[#85DFBF] max_xl:w-[120px]"></div>
        <div className="absolute top-[8px] left-[-8px] -z-10 aspect-square w-full max-w-[140px] cursor-pointer rounded-full border-[2px] border-transparent bg-[#E9F7F2] transition-all duration-150 group-hover:top-0 group-hover:left-0 max_xl:max-w-[120px]">
          <div className="flex h-full w-full items-center justify-center">
            <img className="h-[40px] max_md:h-[30px]" src={icon} alt="icon" />
          </div>
        </div>
      </div>
      <p className="mt-[30px] text-[14px] font-medium leading-[17px] text-[#252525]">
        {name}
      </p>
    </div>
  );
};

export default TechnologyRoundedCard;

// import React from "react";

// const TechnologyRoundedCard = ({ icon, name = "" }) => {
//   return (
//     <div className="flex flex-col items-center">
//       <div className="relative w-full max-w-[140px] max_xl:max-w-[120px] h-full">
//         <div className="absolute z-10 aspect-square w-full max-w-[140px] rounded-full border-[2px] border-[#85DFBF] max_xl:max-w-[120px]">
//         </div>
//           <div className="absolute top-[8px] left-[-8px] -z-10 aspect-square w-full max-w-[140px] cursor-pointer rounded-full border-[2px] border-transparent bg-[#E9F7F2] transition-all duration-150 hover:top-0 hover:left-0 max_xl:max-w-[120px]">
//             <div className="flex h-full w-full items-center justify-center">
//               <img className="h-[40px] max_md:h-[30px]" src={icon} alt="icon" />
//             </div>
//           </div>
//       </div>
//       <p className="mt-[30px] text-[14px] font-medium leading-[17px] text-[#252525]">
//         {name}
//       </p>
//     </div>
//   );
// };

// export default TechnologyRoundedCard;
