import React, { Fragment, useMemo, useState } from "react";
import DeviderLine from "../DeviderLine";
import TechnologyCard from "../TechnologyCard";

import AIMLIMGIcon from "../../assets/common/svgs/Technology/AIML.svg";
import MistralAIIcon from "../../assets/common/svgs/Technology/MistralAI.svg";
import ChatgptIcon from "../../assets/common/svgs/Technology/Chatgpt.svg";
import TensorflowIcon from "../../assets/common/svgs/Technology/Tensorflow.svg";
import OpenCVIcon from "../../assets/common/svgs/Technology/OpenCV.svg";
import HuggingfaceIcon from "../../assets/common/svgs/Technology/Huggingface.svg";
import UnstructuredIcon from "../../assets/common/svgs/Technology/Unstructured.svg";

import MobileIcon from "../../assets/common/svgs/Technology/mobile.svg";
import AndroidIcon from "../../assets/common/svgs/Technology/Android.svg";
import ReactNativeIcon from "../../assets/common/svgs/Technology/React Native.svg";
import iOSIcon from "../../assets/common/svgs/Technology/iOS.svg";
import IonicIcon from "../../assets/common/svgs/Technology/Ionic.svg";
import FlutterIcon from "../../assets/common/svgs/Technology/Flutter.svg";
import PWAIcon from "../../assets/common/svgs/Technology/PWA.svg";

import WebIcon from "../../assets/common/svgs/TechCard.svg";
import ReactIcon from "../../assets/common/svgs/Technology/React.svg";
import AngularIcon from "../../assets/common/svgs/Technology/Angular.svg";
import GatsbyIcon from "../../assets/common/svgs/Technology/Gatsby.svg";
import VueIcon from "../../assets/common/svgs/Technology/Vue.svg";
import NextJsIcon from "../../assets/common/svgs/Technology/Next Js.svg";
import TypescriptIcon from "../../assets/common/svgs/Technology/Typescripts.svg";

import BackendIcon from "../../assets/common/svgs/Technology/backend.svg";
import NodeIcon from "../../assets/common/svgs/Technology/Node.svg";
import PythonIcon from "../../assets/common/svgs/Technology/Python.svg";
import AspDotNetIcon from "../../assets/common/svgs/Technology/ASP.NET.svg";

import PHPIcon from "../../assets/common/svgs/Technology/PHP.svg";
import LaravelIcon from "../../assets/common/svgs/Technology/Laravel.svg";
import CodeIgniterIcon from "../../assets/common/svgs/Technology/CodeIgniter.svg";

import DevOPSIcon from "../../assets/common/svgs/Technology/devops.svg";
import AWSIcon from "../../assets/common/svgs/Technology/AWS.svg";
import GCPIcon from "../../assets/common/svgs/Technology/GCP.svg";
import DockerIcon from "../../assets/common/svgs/Technology/Docker.svg";
import DigitalOceanIcon from "../../assets/common/svgs/Technology/Digital Ocean.svg";
import CICDIcon from "../../assets/common/svgs/Technology/CICD.svg";
import KubernetesIcon from "../../assets/common/svgs/Technology/Kubernetes.svg";

import DatabaseIcon from "../../assets/common/svgs/Technology/database.svg";
import MongoDBIcon from "../../assets/common/svgs/Technology/MongoDB.svg";
import MySQLIcon from "../../assets/common/svgs/Technology/MySQL.svg";
import FirebaseIcon from "../../assets/common/svgs/Technology/Firebase.svg";
import PostgreSQLIcon from "../../assets/common/svgs/Technology/PostgreSQL.svg";
import SQLiteIcon from "../../assets/common/svgs/Technology/SQLite.svg";
import RDSIcon from "../../assets/common/svgs/Technology/RDS.svg";

import CMSIcon from "../../assets/common/svgs/Technology/cms.svg";
import CapacitorIcon from "../../assets/common/svgs/Technology/Capacitor.svg";

import ElectronIcon from "../../assets/common/svgs/Technology/Electron.svg";
import SwiftIcon from "../../assets/common/svgs/Technology/Swift.svg";
import CIcon from "../../assets/common/svgs/Technology/C.svg";
import DotNETIcon from "../../assets/common/svgs/Technology/DotNET.svg";
import TechnologyRoundedCard from "../TechnologyRoundedCard";

// import CMSIcon from "../../assets/common/svgs/Technology/cms.svg"
// import WordpressIcon from "../../assets/common/svgs/Technology/Wordpress.svg"
// import ShopifyIcon from "../../assets/common/svgs/Technology/Shopify.svg"
// import WixIcon from "../../assets/common/svgs/Technology/Wix.svg"
// import ButterCMSIcon from "../../assets/common/svgs/Technology/ButterCMS.svg"
// import DrupalIcon from "../../assets/common/svgs/Technology/Drupal.svg"
// import OctoberIcon from "../../assets/common/svgs/Technology/October.svg"

const Technology = () => {
  const [Technolgys, setTechnolgys] = useState([
    {
      id: 2,
      name: "Mobile",
      icon: MobileIcon,
      tech: [
        {
          icon: AndroidIcon,
          name: "Android",
        },
        {
          icon: ReactNativeIcon,
          name: "React Native",
        },
        {
          icon: iOSIcon,
          name: "iOS",
        },
        {
          icon: IonicIcon,
          name: "Ionic",
        },
        {
          icon: FlutterIcon,
          name: "Flutter",
        },
        {
          icon: PWAIcon,
          name: "PWA",
        },
      ],
    },
    {
      id: 3,
      name: "Web",
      icon: WebIcon,
      tech: [
        {
          icon: ReactIcon,
          name: "React",
        },
        {
          icon: AngularIcon,
          name: "Angular",
        },
        {
          icon: GatsbyIcon,
          name: "Gatsby",
        },
        {
          icon: VueIcon,
          name: "Vue",
        },
        {
          icon: NextJsIcon,
          name: "Next Js",
        },
        {
          icon: TypescriptIcon,
          name: "Typescript",
        },
      ],
    },
    {
      id: 4,
      name: "Backend",
      icon: BackendIcon,
      tech: [
        {
          icon: NodeIcon,
          name: "Node",
        },
        {
          icon: PythonIcon,
          name: "Python",
        },
        {
          icon: AspDotNetIcon,
          name: "ASP.NET",
        },
        {
          icon: PHPIcon,
          name: "php",
        },
        {
          icon: LaravelIcon,
          name: "Laravel",
        },
        {
          icon: CodeIgniterIcon,
          name: "CodeIgniter",
        },
      ],
    },
    {
      id: 5,
      name: "DevOps and Cloud",
      icon: DevOPSIcon,
      tech: [
        {
          icon: AWSIcon,
          name: "AWS",
        },
        {
          icon: GCPIcon,
          name: "GCP",
        },
        {
          icon: DockerIcon,
          name: "Docker",
        },
        {
          icon: DigitalOceanIcon,
          name: "Digital Ocean",
        },
        {
          icon: CICDIcon,
          name: "CI-CD",
        },
        {
          icon: KubernetesIcon,
          name: "Kubernetes",
        },
      ],
    },
    // {
    // id:6,//
    // name: "Database",
    //   icon: DatabaseIcon,
    //   tech: [
    //     {
    //       icon: MongoDBIcon,
    //       name: "MongoDB",
    //     },
    //     {
    //       icon: MySQLIcon,
    //       name: "MySQL",
    //     },
    //     {
    //       icon: FirebaseIcon,
    //       name: "Firebase",
    //     },
    //     {
    //       icon: PostgreSQLIcon,
    //       name: "PostgreSQL",
    //     },
    //     {
    //       icon: SQLiteIcon,
    //       name: "SQLite",
    //     },
    //     {
    //       icon: RDSIcon,
    //       name: "RDS",
    //     },
    //   ],
    // },
    {
      id: 1,
      name: "AI & ML",
      icon: AIMLIMGIcon,
      tech: [
        {
          icon: MistralAIIcon,
          name: "Mistral AI",
        },
        {
          icon: ChatgptIcon,
          name: "OpenAI",
        },
        {
          icon: TensorflowIcon,
          name: "Tensorflow",
        },
        {
          icon: UnstructuredIcon,
          name: "Unstructured",
        },
        {
          icon: OpenCVIcon,
          name: "OpenCV",
        },
        {
          icon: HuggingfaceIcon,
          name: "Hugging Face",
        },
      ],
    },
    {
      id: 6,
      name: "Desktop",
      icon: CMSIcon,
      tech: [
        {
          icon: CapacitorIcon,
          name: "Capacitor",
        },
        {
          icon: FlutterIcon,
          name: "Flutter",
        },
        {
          icon: ElectronIcon,
          name: "Electron",
        },
        {
          icon: SwiftIcon,
          name: "Swift",
        },
        {
          icon: CIcon,
          name: "C#",
        },
        {
          icon: DotNETIcon,
          name: ".NET",
        },
      ],
    },
  ]);

  const [currentSelected, setCurrentSelected] = useState(2);

  const handlePrev = () =>
    setCurrentSelected((prev) => {
      if (prev === 1) {
        return Technolgys.length;
      } else {
        return prev - 1;
      }
    });

  const handleNext = () =>
    setCurrentSelected((prev) => {
      if (prev === Technolgys.length) {
        return 1;
      } else {
        return prev + 1;
      }
    });

  const techList = useMemo(() => {
    return Technolgys.map((itm) => ({ id: itm.id, name: itm.name }));
  }, []);

  const currentDataset = useMemo(() => {
    return Technolgys.find((itm) => itm.id === currentSelected);
  }, [currentSelected]);

  return (
    <>
      <div className="m-auto mb-[120px] w-full max-w-[1240px] max_xl:mb-[80px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:mb-[60px] max_sm:px-[20px]">
        <DeviderLine title="TECHNOLOGY" right="right-[66.33%]" />

        <div className="flex flex-col items-center justify-center">
          <div className="PageTitle mb-[16px] mt-[70px] max_sm:mt-[50px]">
            Technologies we work with
          </div>
        </div>

        <div className="my-[40px] hidden items-center justify-between max_720:flex">
          <button onClick={handlePrev} className="w-[20px]">
            <LeftArrow />
          </button>
          <div className="flex grow justify-center text-[14px] font-semibold leading-[16px] text-bateau underline">
            {techList.find((el) => el.id === currentSelected).name}
          </div>
          <button onClick={handleNext} className="w-[20px]">
            <LeftArrow className="rotate-180" />
          </button>
        </div>

        <div className="mb-[100px] mt-[70px] grid grid-cols-6 place-items-center max_xl:mb-[60px] max_720:hidden max_sm:mt-[50px]">
          {techList.map((tech_type) => (
            <button
              onClick={() => setCurrentSelected(tech_type.id)}
              className={`${
                currentSelected === tech_type.id
                  ? "text-bateau"
                  : "text-[#A8A8A8]"
              }  text-[15px] font-semibold leading-[22px]`}
            >
              {/* {tech_type.name} */}
              {tech_type.name === "DevOps and Cloud" && window.innerWidth < 981
                ? "DevOps"
                : tech_type.name}
            </button>
          ))}
        </div>

        <div className="flex w-full justify-center">
          <div className="grid w-full grid-cols-6 justify-items-center max_md:w-[460px] max_md:grid-cols-3 max_md:gap-[40px] max_sm:w-[280px] max_sm:grid-cols-2">
            {currentDataset.tech.map((tech, idx) => {
              return (
                <TechnologyRoundedCard
                  icon={tech.icon}
                  name={tech.name}
                  key={idx}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* COMMENTED 28-11-24 */}
      {/* <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="TECHNOLOGY" right="right-[66.33%]" />
        <div className="mt-[80px] flex justify-between  max_md:mt-[60px] max_md:flex max_md:justify-center">
          <div className="PageContentText w-full max-w-[355px] leading-[24px]  max_md:text-center">
            We pride ourselves on using the latest and most advanced
            technologies to deliver cutting-edge solutions to our clients.
          </div>
          <div className="max_md:hidden">
            <ButtonOutliedIcon title="START A PROJECT" />
          </div>
        </div>
        <div className="mt-[80px] mb-[120px] flex flex-wrap justify-between max_xl:justify-center max_xl:gap-x-[22px] max_md:mt-[40px] max_md:mb-[100px]">
          {Technolgys.map((itm, index) => (
            <Fragment key={index}>
              <TechnologyCard itm={itm} />
            </Fragment>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default Technology;

const LeftArrow = (props) => (
  <svg
    {...props}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.375 9.50002L6.33333 13.4584M2.375 9.50002L6.33333 5.54169M2.375 9.50002H16.625"
      stroke="#2275A1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
