import { Button, Menu, MenuHandler, MenuItem, MenuList } from "@material-tailwind/react";
import { countryCode } from "../../utils/countryCode";
import { Fragment } from "react";

export const MobileNumber = ({ isFocused, setIsFocused, cCodd, setCCode, handleOnChange,value }) => {
  return (
    <div className="relative">
      <div className="absolute bottom-[-24px] text-[12px] text-red-400">
      </div>
      <label
        className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
      >
        {"Phone Number*"}
      </label>
      <div
        className={`bg-moon-white group mt-[9px] flex h-[50px] max_sm:h-[38px] rounded-[8px]`}
      >
        <Menu placement="bottom-start">
          <MenuHandler>
            <Button
              ripple={false}
              variant="text"
              color="blue-gray"
              className="flex h-full w-[100px] items-center justify-center p-0 pl-[15px] pr-[10px] text-duskwood hover:bg-transparent focus-visible:outline-0 active:bg-transparent"
            >
              {cCodd}
            </Button>
          </MenuHandler>
          <div className="h-[26px] max_sm:h-[18px] w-[1px] min-w-[1px] self-center bg-duskwood"></div>

          <MenuList className="max-h-[20rem] max-w-[18rem]">
            {countryCode.map(({ name, code, dial_code }, index) => {
              return (
                <Fragment key={index}>
                  <MenuItem
                    key={name}
                    value={name}
                    className="flex items-center gap-2"
                    onClick={() => setCCode(dial_code)}
                  >
                    {name} <span className="ml-auto">{dial_code}</span>
                  </MenuItem>
                </Fragment>
              );
            })}
          </MenuList>
        </Menu>
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          type="number"
          required="required"
          autoComplete="off"
          className="focus-visible:none w-full bg-transparent px-[12px] text-[17px] font-medium text-duskwood focus:outline-none focus:outline-[0px] focus:outline-offset-0 focus:outline-bateau max_xl:px-[16px] max_md:text-[14px]"
          placeholder={"Phone Number"}
          name="phoneNumber"
          onChange={(e) => handleOnChange(e)}
          value={value}
        />
      </div>
    </div>
  )
}