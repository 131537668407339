import React from "react";
import DeviderLine from "../DeviderLine";
import { EvenProcessCard, OddProcessCard } from "../ProcessCard";
import ResearchIMG from "../../assets/common/svgs/research.svg";
import DesignIMG from "../../assets/common/svgs/design.svg";
import DevelopIMG from "../../assets/common/svgs/develop.svg";
import DeployIMG from "../../assets/common/svgs/deploy.svg";

const Process = () => {
  return (
    <>
      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="PROCESS" right="right-[33.33%]" />
        <div className="mt-[80px] flex justify-between  max_md:mt-[60px] max_md:flex max_md:justify-center">
          {/* <div className="PageContentText w-full max-w-[355px] leading-[24px]  max_md:text-center">
            At iGeek we follow a well-defined working process to ensure the
            highest quality of service to our clients.
          </div> */}
          {/* <div className="max_md:hidden">
            <ButtonOutliedIcon title="START A PROJECT" />
          </div> */}
        </div>
        <div className="mb-[120px] mt-[135px] flex flex-wrap justify-between gap-y-[100px] max_xl:justify-center max_xl:gap-x-[22px] max_md:mb-[120px] max_720:gap-y-[65px]">
          <OddProcessCard
            title="Research"
            content="The process begins with understanding and defining the needs and objectives of the software resulting in a requirements specification document."
            image={ResearchIMG}
          />
          <div className="max_720:mb-[70px]">
            <EvenProcessCard
              title="Design"
              content="Based on the research and analysis we create designs and prototype for the product and it is tested and refined until it meets the expectations."
              image={DesignIMG}
            />
          </div>
          <OddProcessCard
            title="Development"
            content="During this phase, the software architecture is built where ideas and designs transform into a working software product."
            image={DevelopIMG}
          />
          <EvenProcessCard
            title="Deployment"
            content="After successful testing, the software is deployed in the target environment, making it available to end-users."
            image={DeployIMG}
          />
        </div>
      </div>
    </>
  );
};

export default Process;
