import React from "react";
import useWindowWidth from "../utils/useWindowsize";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "../assets/common/Icons/icons";

const JobCard = ({
  title = "React JS Developer",
  location = "Surat",
  experience = "1 to 2 Years",
  position = "2",
  id,
  setHoverIndex,
  hoverIndex,
  onApply,
  object
}) => {
  const windowWidth = useWindowWidth()
  const isDesktop = windowWidth < 1300
  const navigate = useNavigate()
  return (
    <div className="flex rounded-[10px] border-[0.75px] border-hazy-daze p-[24px] max_xl:p-4 items-center hover:bg-[#f6f9fa] justify-between max_sm:justify-start cursor-pointer" onMouseEnter={() => setHoverIndex(id)} onMouseLeave={() => setHoverIndex(null)} onClick={()=>onApply(object)}>
      <div className="flex max_sm:gap-[10px]">
        <div className=" flex justify-center items-center">
          <img
            draggable="false"
            className="w-[35px] h-[37px] max_sm:w-[27px] max_sm:h-[30px]"
            src={object.icon}
            alt=""
          />
        </div>
        <div className="flex flex-col justify-between py-[5px] pl-[15px] max_sm:py-0 max_xl:py-[8px] max_sm:pl-[0px]">
          <div className="flex items-center justify-between max_sm:mb-1">
            <div className="text-LargeSize font-semibold text-duskwood max_md:text-[13px]">
              {title}
            </div>
          </div>
          <div className="flex w-full items-end gap-5 max_xl:gap-2">
            <div className="text-SmallSize font-medium text-bateau max_md:text-[10px]">
              Location:{" "}
              <span className="text-[13px] font-semibold text-duskwood max_xl:text-[13px] max_md:text-[10px]">
                {location}
              </span>
            </div>
            <div className="text-SmallSize font-medium text-bateau  max_md:text-[10px]">
              Experience:{" "}
              <span className="text-[13px] font-semibold text-duskwood max_xl:text-[13px] max_md:text-[10px]">
                {experience}
              </span>
            </div>
            <div className="text-SmallSize font-medium text-bateau  max_md:text-[10px]">
              Position:{" "}
              <span className="text-[13px] font-semibold text-duskwood max_xl:text-[13px] max_md:text-[10px]">
                {position}
              </span>
            </div>
            {/* <div className="cursor-pointer text-SmallSize font-medium text-her-sub-heading max_xl:hidden">
            <div
              className={`flex h-[34px] w-[90px] items-center justify-center rounded-[4px] border-[1px] border-hazy-daze hover:border-activeMenu text hover:bg-activeMenu hover:text-white`}
            >
              <div className="text-[11px] font-semibold " onClick={() => navigate(`/career/jobs/${id}`)}>
                View Detail
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <div className="max_sm:hidden">

        <div className={`mr-5 max_xl:mr-0 ${hoverIndex === id ? 'block' : 'hidden'}`}>
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default JobCard;
