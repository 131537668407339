import React, { useState } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import WebDevelopIMG from "../../assets/common/svgs/service/WebDevelopIMG.svg";
import WEBIMG from "../../assets/common/svgs/web.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";
import Testimonial from "../../Components/pageComponent/Testimonial";
import ServiceCard from "../../Components/ServiceCard";
import DeviderLine from "../../Components/DeviderLine";

const ServiceWeb = () => {
  const [serviceData, setserviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek for Web Development Services?",
    Image: WebDevelopIMG,
    description:
      "Our web development services are designed to help businesses create a strong online presence and enhance their digital capabilities. We offer solutions are tailored to our clients' specific needs and requirements, ensuring that their websites are user-friendly, responsive, and optimized for search engines.",
    title: "Our Web Development services",
    cards: [
      {
        Image: WEBIMG,
        title: "E-Commerce Web App Development",
        background: "bg-placebo-turquoise",
        description:
          "Our IT company specializes in E-Commerce Web App Development, providing businesses with robust and scalable solutions that meet their specific needs and objectives.",
      },
      {
        Image: WEBIMG,
        title: "Progressive Web Apps",
        background: "bg-clear-skies",
        description:
          "As a progressive web application development company, we specialize in creating web applications that offer a native-like experience to users across all devices and platforms.",
      },
      {
        Image: WEBIMG,
        title: "Website Development",
        background: "bg-placebo-turquoise",
        description:
          "Our website development process is tailored to meet the specific requirements of each project, with a focus on collaboration, transparency, and quality.",
      },
      {
        Image: WEBIMG,
        title: "Web Application Testing",
        background: "bg-clear-skies",
        description:
          "We are expert in web application testing to ensure that our clients' web applications meet the specified requirements and provide a seamless user experience.",
      },
      {
        Image: WEBIMG,
        title: "Web Application Support & Maintenance",
        background: "bg-placebo-turquoise",
        description:
          "Our web application support and maintenance services are designed to help businesses optimize their web applications and ensure that they remain updated, secure, and efficient.",
      },
    ],
  });

  const [otherService, setotherService] = useState([
    {
      Image: ToolIMG,
      title: "UI/UX Design",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-ui-ux",
    },
    {
      Image: ToolIMG,
      title: "Mobile app Development",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-mobile-development",
    },
    {
      Image: ToolIMG,
      title: "Product Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-product-development",
    },
    {
      Image: ToolIMG,
      title: "Dedicated Developer/Team",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-developer",
    },
    {
      Image: ToolIMG,
      title: "QA/Testing",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-testing",
    },
  ]);

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[980px] max_md:px-[50px] max_sm:px-[20px]">
                Build your online presence with our Web Development Services
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                Our expertise in web design, development, and optimization
                enables us to deliver high-quality websites that are
                user-friendly, responsive, and optimized for search engines.
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[435px]"}
              serviceData={serviceData}
            />
          </div>

          {/* <div className="mt-[160px] max_md:mt-[80px]">
            <Testimonial />
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <div className="mt-[160px] max_md:mt-[80px]">
              <DeviderLine title="OTHERS" right="right-[75%]" />

              <div className="mt-[65px] flex flex-wrap justify-center gap-[15px] max_md:mt-[40px]">
                <div className="w-[403px] pt-[40px] text-[30px] font-bold leading-[45px] text-duskwood max_md:pt-[0px] max_md:pb-[40px] max_md:text-ExLargeSize max_md:leading-[32px] ">
                  <div className="max-w-[362px]">
                    We Provide Other Exclusive Services For Turn Your Business
                    Into A Brand
                  </div>
                </div>

                {otherService.map((cardData, index) => (
                  <Fragment key={index}>
                    <ServiceCard
                      title={cardData.title}
                      desc={cardData.description}
                      background={cardData.background}
                      readMore={cardData.link}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceWeb;
