import React from "react";
import { ReactComponent as ArrowIcon } from "../assets/common/svgs/Arrow.svg";
import ToolIMG from "../assets/common/svgs/ToolImg.svg";
import { useNavigate } from "react-router-dom";

// import XD from "../assets/common/svgs/uiux/XD.svg"
import XD from "../assets/common/svgs/uiux/XD.svg";
import Figma from "../assets/common/svgs/uiux/Figma.svg";
import Illuster from "../assets/common/svgs/uiux/Illuster.svg";
import Photoshop from "../assets/common/svgs/uiux/Photoshop.svg";
import Sketch from "../assets/common/svgs/uiux/Sketch.svg";
import Webflow from "../assets/common/svgs/uiux/Webflow.svg";
import { useState } from "react";

const ServiceCard = ({
  title = "title",
  desc = "desc",
  background = "",
  readMore = null,
  icon = null
}) => {
  const navigate = useNavigate();
  const [uiuxIcon, setuiuxIcon] = useState([
    {
      icon: XD,
      title: "Adobe XD",
    },
    {
      icon: Illuster,
      title: "Illustrator",
    },
    {
      icon: Figma,
      title: "Figma",
    },
    {
      icon: Photoshop,
      title: "Photoshop",
    },
    {
      icon: Sketch,
      title: "Webflow",
    },
    {
      icon: Webflow,
      title: "Sketch",
    },
  ]);
  return (
    <>
      <div
        className={`flex flex-col rounded-[13px] p-[30px] max_sm:p-[20px] ${
          background ? background : "bg-placebo-turquoise"
        }  w-[403px]  max_sm:w-[340px]`}
      >
        <div>
          <img draggable="false" src={icon || ToolIMG} className="w-[50px] h-[50px]" alt="" />
        </div>
        <div className="mt-[14px]">
          <div className="text-LargeSize font-semibold text-bateau max_sm:text-[13px]">
            {title}
          </div>
          {title !== "Tools we use" ? (
            <div className="mt-[18px] mb-[10px] text-RegularSize font-medium text-her-sub-heading max_sm:mt-[10px] max_sm:text-[11px]">
              {desc}
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-[25px] mt-[18px] text-RegularSize max_sm:mt-[10px] ">
              {uiuxIcon.map((itm, index) => (
                <div key={index} className="flex gap-x-[8px] text-RegularSize max_sm:text-[11px] items-center">
                  <img draggable="false" src={itm.icon} alt="" />
                  {itm.title}
                </div>
              ))}
            </div>
          )}
          {readMore && (
            <div
              className="flex cursor-pointer items-center gap-x-[8px] text-MediumSize max_sm:text-[13px]"
              onClick={() => navigate(readMore)}
            >
              Read more{" "}
              <span>
                <ArrowIcon
                  className="BlackIcon"
                  style={{
                    width: "12px",
                  }}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
