import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import HomeTopBG1 from "../../assets/common/svgs/HEROBG.svg";
import Navbar from "../Navbar";
import Footer from "./Footer";
import MenuDropDown from "./MenuDropDown";
import { useLocation } from "react-router-dom";
import MobileMenu from "../MobileMeneComp/MobileMenu";
import { CSSTransition } from "react-transition-group";
import "./layout.css";
import { useOnClickOutside } from "../../utils/OutsideClick";

const Layout = ({ children }) => {
  const location = useLocation();
  const nodeRef = useRef(null);
  const outsideRef = useRef(null);
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOverFLowX, serIsOverFlowX] = useState(true);

  useOnClickOutside(outsideRef, () => setIsMobileMenuOpen(false));

  useEffect(() => {
    if (location.pathname === "/") {
      serIsOverFlowX(false);
    }
  }, [location]);

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", changeBackground);
      };
    }
  });

  useEffect(() => {
    const body = document.querySelector("body");
    if (isMobileMenuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <div className="MAINBODY relative w-full">
        <div className="absolute top-[-100px] left-0 flex h-[350px] w-full justify-center ">
          <img
            draggable="false"
            className="h-full w-full max-w-[1920px] object-cover object-bottom"
            src={HomeTopBG1}
            alt=""
          />
        </div>
        <div
          className={`fixed top-0 z-50 w-full ${
            navbarChangeColor || isServiceOpen
              ? "bg-white shadow-lg"
              : "bg-transparent"
          } `}
        >
          <div className="relative m-auto w-full ">
            {isServiceOpen && (
              <div
                onMouseLeave={() => setIsServiceOpen(false)}
                style={{
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
                }}
                className="absolute top-full h-[86px] w-full border-[1px] border-t-[#DBF1E9] bg-white p-[10px]"
              >
                <MenuDropDown setIsServiceOpen={setIsServiceOpen} />
              </div>
            )}
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              setIsServiceOpen={setIsServiceOpen}
            />
          </div>
          {/* {isMobileMenuOpen && ( */}
          <CSSTransition
            in={isMobileMenuOpen}
            nodeRef={nodeRef}
            timeout={500}
            classNames="menu"
            type={"out-in" | "in-out"}
            unmountOnExit
            onEnter={() => setIsMobileMenuOpen(true)}
            onExited={() => setIsMobileMenuOpen(false)}
          >
            <MobileMenu
              nodeRef={nodeRef}
              outsideRef={outsideRef}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
            />
          </CSSTransition>
          {/* )} */}
        </div>
        <div className={`${isOverFLowX && "overflow-x-hidden"}`}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
