import React from "react";
import { ReactComponent as GradIcon } from "../assets/common/svgs/GrdientCircle.svg";

const DeviderLine = ({ title = "Title", right = "right-[25%]" }) => {
  return (
    <>
      <div className="F-JC-AI-CENTER  w-full justify-start">
        <div className="PageSeperaterText">{title}</div>
        <div className="relative grow ml-[20px]">
          <div className="h-[1px] w-full bg-[#51bd9780]" />
          <div className={`absolute ${right} top-[-14px]`}>
            <GradIcon className="max_md:w-[22px]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviderLine;
