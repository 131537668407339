import React, { Fragment, useState } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import MobileDevelopIMG from "../../assets/common/svgs/service/MobiledevelopIMG.svg";
import MOBILEIMG from "../../assets/common/svgs/mobile.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";
import Testimonial from "../../Components/pageComponent/Testimonial";
import ServiceCard from "../../Components/ServiceCard";
import DeviderLine from "../../Components/DeviderLine";

const ServiceMobile = () => {
  const [serviceData, setserviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek for Mobile App Development services?",
    Image: MobileDevelopIMG,
    description:
      "We specialize in creating custom mobile apps that meet your business needs and engage your users. Our team of skilled developers uses the latest tools and technologies to build high-performing, feature-rich mobile apps that run seamlessly on both iOS and Android platforms.",
    title: "Our Mobile App Development services",

    cards: [
      {
        Image: MOBILEIMG,
        title: "Android Apps",
        background: "bg-placebo-turquoise",
        description:
          "Our Android app development process is tailored to meet the specific requirements of each project, with a focus on quality to standout from all other competitor on play store.",
      },
      {
        Image: MOBILEIMG,
        title: "iOS Apps",
        background: "bg-clear-skies",
        description:
          "Our team of expert developers works closely in creating iOS mobile applications for clients right from ideation to launch and compatible on all appel devices.",
      },
      {
        Image: MOBILEIMG,
        title: "Hybrid Apps",
        background: "bg-placebo-turquoise",
        description:
          "Our team of skilled developers specializes in building cross-platform mobile applications that run seamlessly on both iOS and Android devices.",
      },
      {
        Image: MOBILEIMG,
        title: "Mobile App Testing",
        background: "bg-clear-skies",
        description:
          "With our state-of-the-art testing infrastructure and experienced testers, we conduct thorough testing to identify issues that could affect the user experience or security.",
      },
      {
        Image: MOBILEIMG,
        title: "Mobile App Support & Maintenance",
        background: "bg-placebo-turquoise",
        description:
          "We provide ongoing support and regular maintenance committed to providing reliable help to maximize your app's potential and achieve your business goals.",
      },
    ],
  });

  const [otherService, setotherService] = useState([
    {
      Image: ToolIMG,
      title: "Web Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-web-development",
    },
    {
      Image: ToolIMG,
      title: "UI/UX Design",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-ui-ux",
    },
    {
      Image: ToolIMG,
      title: "Product Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-product-development",
    },
    {
      Image: ToolIMG,
      title: "Dedicated Developer/Team",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-testing",
    },
    {
      Image: ToolIMG,
      title: "QA/Testing",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-ui-ux",
    },
  ]);

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img draggable="false" className="h-full object-cover" src={HomeBottomBG1} alt="" />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[980px] max_md:px-[50px] max_sm:px-[20px]">
                End-To-End Application Modernization Services With iGeek
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                We build high-performing mobile apps tailored to your business
                needs
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[490px]"}
              serviceData={serviceData}
            />
          </div>

          {/* <div className="mt-[160px] max_md:mt-[80px]">
            <Testimonial />
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <div className="mt-[160px] max_md:mt-[80px]">
              <DeviderLine title="OTHERS" right="right-[75%]" />

              <div className="mt-[65px] flex flex-wrap justify-center gap-[15px] max_md:mt-[40px]">
                <div className="w-[403px] pt-[40px] text-[30px] font-bold leading-[45px] text-duskwood max_md:pt-[0px] max_md:pb-[40px] max_md:text-ExLargeSize max_md:leading-[32px] ">
                  <div className="max-w-[362px]">
                    We Provide Other Exclusive Services For Turn Your Business
                    Into A Brand
                  </div>
                </div>

                {otherService.map((cardData, index) => (
                  <Fragment key={index}>
                    <ServiceCard
                      title={cardData.title}
                      desc={cardData.description}
                      background={cardData.background}
                      readMore={cardData.link}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceMobile;
