import React, { useState } from "react";
import DeviderLine from "../DeviderLine";
import { ButtonFilled, ButtonOutliedIcon } from "../utils/Buttons";
import { useNavigate } from "react-router-dom";
import aiml from "../../assets/common/services/aiml.jpg";
import uiux from "../../assets/common/services/uiux.jpg";
import web from "../../assets/common/services/web.jpg";
import mobile from "../../assets/common/services/mobile.jpg";
import staff from "../../assets/common/services/staff.jpg";
import product from "../../assets/common/services/product.jpg";
import qulity from "../../assets/common/services/qulity.jpg";

const ServiceComp = () => {
  const navigate = useNavigate();
  const [services] = useState([
    {
      title: "✨AI",
      discription:
        "Elevate your business with our ML, and AI services. Transform data into actionable insights and predictive models, propelling you to the forefront of innovation and efficiency.",
      image: aiml,
      path: "/ai-ml",
    },
    {
      title: "Web Development",
      discription:
        "Empower your online presence with our state-of-the-art web development services. We build robust, responsive, and scalable websites tailored to your business needs.",
      image: web,
      path: "/services-web-development",
    },
    {
      title: "Mobile Development",
      discription:
        "Take your business to the next level with our exceptional mobile app development services. We create dynamic, user-friendly, and high-performance apps for iOS and Android platforms.",
      image: mobile,
      path: "/services-mobile-development",
    },
    {
      title: "UI/UX Design",
      discription:
        "Elevate your digital experience with our innovative and user-centered UI/UX design services. Our expert team combines creativity and functionality to deliver captivating and seamless user experiences.",
      image: uiux,
      path: "/services-ui-ux",
    },
    {
      title: "Product Development",
      discription:
        "Transform your vision into reality with our end-to-end product development services. Our expert team collaborates closely with you to design, engineer, and launch innovative products.",
      image: product,
      path: "/services-product-development",
    },
    {
      title: "Quality Analysis",
      discription:
        "Ensure your software meets the highest standards with our quality analysis services. Our expert team of QA analysts conducts in-depth testing to deliver a flawless user experience across platforms.",
      image: qulity,
      path: "/services-testing",
    },
    {
      title: "Staff Augmentation",
      discription:
        "Scale your projects effortlessly with our reliable developer team. Our skilled professionals work as an extension of your team, delivering exceptional results across web, mobile, and software development.",
      image: staff,
      path: "/services-developer",
    },
  ]);

  const handleClickScroll = () => {
    const element = document.getElementById("contact-us-redireaction-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="abc-test m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="Service" right="right-[0%]" />

        {/* PART 1 */}
        <div className="mt-[80px] flex justify-between  max_md:mt-[60px] max_md:flex max_md:justify-center">
          <div className="PageContentText w-full max-w-[355px] leading-[24px]  max_md:text-center">
            Expert in providing cutting-edge technology solutions to help
            businesses of all sizes achieve their goals.
          </div>
          <div className="max_md:hidden">
            <ButtonOutliedIcon
              title="Get a Quote"
              onClick={() => {
                navigate("/contact-us");
                setTimeout(() => {
                  handleClickScroll();
                }, 500);
              }}
            />
          </div>
        </div>

        <div className="mb-[120px] mt-[95px] flex flex-col gap-2 max_md:mb-[100px] max_md:mt-[60px]">
          {services.map((service, index) => {
            const bottom = `${15 - index * 2}vh`;
            const zIndex = 11 - index;
            return (
              <div
                key={index}
                style={{
                  boxShadow: "0px 10px 20px 0px rgba(138, 138, 138, 0.10)",
                  zIndex: zIndex,
                  bottom: bottom,
                }}
                className={`align-center sticky left-0 flex h-[500px] w-[100%] select-none overflow-hidden rounded-[40px] bg-cover bg-center max_md:h-auto max_720:h-auto max_sm:h-auto`}
              >
                <img
                  className="absolute left-0 top-0 -z-10 h-[100%] w-[100%] object-cover "
                  src={service.image}
                  alt="ui"
                />
                <div className="z-20 w-[55%] p-[80px] max_xl:w-[70%] max_xl:p-[60px] max_md:w-[100%] max_sm:p-[40px]">
                  <div className="text-[50px] font-semibold text-duskwood max_xl:text-[40px] max_md:text-[30px] max_sm:text-[20px]">
                    {service.title}
                  </div>
                  <div className="PageContentText w-full pt-[20px] leading-[24px]">
                    {service.discription}
                  </div>
                  <div className="pt-[50px]">
                  <ButtonFilled 
                      onClick={() => navigate(service.path)}
                      title=" View More"
                      className="w-[190px]"
                  />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ServiceComp;
