import React, { useEffect, useState, Fragment } from "react";
import LOGOSVG from "../../assets/common/svgs/iGeek.svg";
import CloseIcon from "../../assets/common/svgs/CloseIcon.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ArrICON from "../../assets/common/svgs/ArrIcon.svg";
import WEBIMG from "../../assets/common/svgs/web.svg";
import UIUXIMG from "../../assets/common/svgs/ui-ux.svg";
import MOBILEIMG from "../../assets/common/svgs/mobile.svg";
import PRODDEVIMG from "../../assets/common/svgs/prodDev.svg";
import TESTIMG from "../../assets/common/svgs/testing.svg";
import DEVELOPERIMG from "../../assets/common/svgs/developer.svg";

const MobileMenu = ({ setIsMobileMenuOpen, nodeRef, outsideRef }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [MenuList] = useState([
    {
      name: "✨AI",
      link: "/ai-ml",
    },
    {
      name: "Case Study",
      link: "/case-study",
    },
    {
      name: "Services",
      link: "",
      subRoute: [
        {
          Img: WEBIMG,
          name: "Web Development",
          path: "/services-web-development",
        },
        {
          Img: MOBILEIMG,
          name: "Mobile App Development",
          path: "/services-mobile-development",
        },
        {
          Img: UIUXIMG,
          name: "UI/UX Design",
          path: "/services-ui-ux",
        },
        {
          Img: PRODDEVIMG,
          name: "Product Development",
          path: "/services-product-development",
        },
        {
          Img: TESTIMG,
          name: "Quality Analysis",
          path: "/services-testing",
        },
        {
          Img: DEVELOPERIMG,
          name: "Staff Augmentation",
          path: "/services-developer",
        },
      ],
    },
    // {
    //   name: "Hire Us",
    //   link: "/hire-us",
    // },
    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Career",
      link: "/career",
    },
    {
      name: "Contact Us",
      link: "/contact-us",
    },
  ]);
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  useEffect(() => {
    if (location.pathname.includes("/services")) {
      setIsServiceOpen(true);
    }
  }, [location]);

  return (
    <>
      <div
        ref={nodeRef}
        className="z-90 fixed top-0 flex h-screen w-[100%] overflow-hidden"
      >
        <div ref={outsideRef} className="w-full max-w-[330px] bg-white">
          <div
            style={{
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07)",
            }}
            className="F-JC-AI-CENTER h-[70px] justify-between px-[20px]"
          >
            <div onClick={() => navigate("/")} className="w-[120px]">
              <img draggable="false" src={LOGOSVG} alt="" />
            </div>
            <div onClick={() => setIsMobileMenuOpen(false)}>
              <img draggable="false" src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="z-100 h-[calc(100vh-70px)] overflow-y-scroll">
            {MenuList.map((menu, index) => (
              <Fragment key={index}>
                <NavLink
                  className={`flex w-full items-center ${
                    MenuList.length - 1 === index ||
                    (isServiceOpen && menu.name == "Services")
                      ? "border-b-[0px]"
                      : "border-b-[1px]"
                  }  border-[#00000033] p-[20px] no-underline`}
                  to={menu.link}
                >
                  {({ isActive }) => (
                    <div className="flex w-full flex-col">
                      <div
                        onClick={() => setIsServiceOpen(!isServiceOpen)}
                        className="flex w-full justify-between"
                      >
                        <div
                          className={`text-RegularSize ${
                            (isActive && menu.link !== "") ||
                            (isActive && location.pathname.includes("service"))
                              ? "font-semibold text-activeMenu"
                              : "text-her-sub-heading"
                          } cursor-pointer hover:text-activeMenu`}
                        >
                          {menu.name}
                        </div>
                        {menu.name === "Services" && (
                          <img
                            draggable="false"
                            src={ArrICON}
                            className="rotate-[270deg]"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  )}
                </NavLink>
                {menu.link == "" && isServiceOpen && (
                  <div className="border-b-[1px] border-b-[#00000033] px-[20px]">
                    {menu.subRoute.map((subMenu, index1) => (
                      <div
                        key={index1}
                        onClick={() => {
                          navigate(subMenu.path);
                        }}
                        className="flex items-center py-[13px]"
                      >
                        <div className="flex w-[50px] justify-center">
                          <img
                            draggable="false"
                            className={`${
                              subMenu.name === "Mobile App Development"
                                ? "h-[23px] w-[17px]"
                                : "w-[22px]"
                            } `}
                            src={subMenu.Img}
                            alt="i"
                          />
                        </div>
                        <div
                          className={`${
                            location.pathname === subMenu.path
                              ? "font-semibold text-duskwood"
                              : "text-her-sub-heading"
                          } text-SmallSize`}
                        >
                          {subMenu.name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <div className="grow bg-[#00000080]"></div>
      </div>
    </>
  );
};

export default MobileMenu;
