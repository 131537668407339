import React, { useState } from "react";
import BottomIMG from "../assets/common/svgs/CardBottom.svg";
import TopIMG from "../assets/common/svgs/CardTop.svg";
import ResearchIMG from "../assets/common/images/research.png";
import VerticalBG from "../assets/common/svgs/VerticalBG.svg";
import VerticalBG1 from "../assets/common/svgs/VerticalBG1.svg";

export const OddProcessCard = ({
  title = "title",
  content = "content",
  image = ResearchIMG,
}) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  return (
    <>
      <div className="relative flex h-[260px] w-[300px] justify-center">
        {windowWidth < 720 ? (
          <img draggable="false"
            style={{
              filter:
                "drop-shadow(3.04668px 6.09337px 15.2334px rgba(0, 0, 0, 0.25))",
            }}
            className="absolute bottom-[-4px] left-[-2px] h-[117%]"
            src={VerticalBG}
            alt=""
          />
        ) : (
          <img draggable="false"
            style={{
              filter:
                "drop-shadow(3.04668px 6.09337px 15.2334px rgba(0, 0, 0, 0.25))",
            }}
            className="absolute bottom-0"
            src={BottomIMG}
            alt=""
          />
        )}

        <div
          style={{
            boxShadow: "0px 3.04668px 15.2334px rgba(0, 0, 0, 0.1)",
          }}
          className="F-JC-AI-CENTER justify-start pt-[80px] absolute bottom-[18px] z-10 h-[260px] w-[260px] flex-col rounded-[22px] bg-white"
        >
          <div className="ProcessCardHeading mb-[14px]">{title}</div>
          <div className="ProcessCardText max-w-[196px] text-center">
            {content}
          </div>
        </div>
        <div className="absolute top-[-75px] z-10">
          <div
            style={{
              background:
                "linear-gradient(219.27deg, #4AB597 12.13%, #2275A1 85.17%)",
              boxShadow: "0px 3.04668px 7.61671px rgba(0, 0, 0, 0.25)",
            }}
            className="bg- flex h-full w-full items-center justify-center rounded-full p-[14px]"
          >
            <div
              style={{
                boxShadow: "0px 3.04668px 7.61671px rgba(0, 0, 0, 0.25)",
              }}
              className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-white"
            >
              <img draggable="false"
                style={{
                  filter:
                    "drop-shadow(0px 3.04668px 7.61671px rgba(0, 0, 0, 0.25)",
                }}
                src={image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EvenProcessCard = ({
  title = "title",
  content = "content",
  image = ResearchIMG,
}) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  return (
    <>
      <div className="relative flex h-[260px] w-[300px] justify-center">
        {windowWidth < 720 ? (
          <img draggable="false"
            style={{
              filter:
                "drop-shadow(3.04668px 6.09337px 15.2334px rgba(0, 0, 0, 0.25))",
            }}
            className="absolute bottom-[-4px] right-[-2px] h-[117%]"
            src={VerticalBG1}
            alt=""
          />
        ) : (
          <img draggable="false"
            style={{
              filter:
                "drop-shadow(3.04668px 6.09337px 15.2334px rgba(0, 0, 0, 0.25))",
            }}
            className="absolute top-[-36px]"
            src={TopIMG}
            alt=""
          />
        )}

        <div
          style={{
            boxShadow: "0px 3.04668px 15.2334px rgba(0, 0, 0, 0.1)",
          }}
          className="F-JC-AI-CENTER absolute bottom-[18px] z-10 h-[260px] w-[260px] flex-col justify-start rounded-[22px] bg-white"
        >
          <div className="ProcessCardHeading mt-[29px] mb-[14px]">{title}</div>
          <div className="ProcessCardText max-w-[196px] text-center">
            {content}
          </div>
        </div>
        <div className="absolute bottom-[-37px] z-10">
          <div
            style={{
              background:
              "linear-gradient(219.27deg,#2275A1 12.13%,#4AB597  85.17%)",
              boxShadow: "0px 3.04668px 7.61671px rgba(0, 0, 0, 0.25)",
            }}
            className="bg- flex h-full w-full items-center justify-center rounded-full p-[14px]"
          >
            <div
              style={{
                boxShadow: "0px 3.04668px 7.61671px rgba(0, 0, 0, 0.25)",
              }}
              className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-white"
            >
              <img draggable="false"
                style={{
                  filter:
                    "drop-shadow(0px 3.04668px 7.61671px rgba(0, 0, 0, 0.25)",
                }}
                src={image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
