import React from "react";
import NotFoundIMG from "../assets/common/svgs/404/404.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex min-h-[100dvh] bg-[#EEF9F5]">
      <div className="m-auto h-full w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <div className="flex h-full flex-col items-center justify-center">
          <div className="mb-[20px] text-[64px] font-black leading-[96px] text-[#252525] max_md:text-[45px] max_md:leading-[50px]">
            Oops!
          </div>
          <div className="mb-[100px] text-center text-[24px] leading-[36px] text-[#252525] max_md:text-[20px] max_md:leading-[28px]">
            The page you are looking for does not exist. it might have been
            moved or delete.
          </div>
          <div className="mb-[100px]">
            <img draggable="false" src={NotFoundIMG} alt="" />
          </div>
          <div
            onClick={() => navigate("/")}
            className="border-b-[1px] cursor-pointer border-[#252525] text-[24px] leading-[36px] text-[#252525] max_md:text-[20px] max_md:leading-[28px]"
          >
            Go Home
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
