import React, { useState } from "react";
import note from "../assets/common/images/privacyPolicy/note.png";
import InterpretationDefinitions from "../Components/PrivacyPolicy/InterpretationDefinitions";
import CollectingPersonalData from "../Components/PrivacyPolicy/CollectingPersonalData";

const PrivacyPolicy = () => {
  const [CompanyUsePersonalData] = useState([
    {
      title: "To provide and maintain our Service,",
      description: " including to monitor the usage of our Service.",
    },
    {
      title: "To manage Your Account:",
      description:
        " to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
    },
    {
      title: "For the performance of a contract:",
      description:
        " the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
    },
    {
      title: "To contact You:",
      description:
        " To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    },
    {
      title: "To provide You",
      description:
        " with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
    },
    {
      title: "To manage Your requests:",
      description: " To attend and manage Your requests to Us.",
    },
    {
      title: "For business transfers:",
      description:
        " We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
    },
    {
      title: "For other purposes:",
      description:
        " We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.",
    },
  ]);

  const [PersonalInformation] = useState([
    {
      title: "With Service Providers:",
      description:
        " We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.",
    },
    {
      title: "For business transfers:",
      description:
        " We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
    },
    {
      title: "With Affiliates:",
      description:
        " We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
    },
    {
      title: "With business partners:",
      description:
        " We may share Your information with Our business partners to offer You certain products, services or promotions.",
    },
    {
      title: "With other users:",
      description:
        " when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.",
    },
    {
      title: "With Your consent:",
      description:
        " We may disclose Your personal information for any other purpose with Your consent.",
    },
  ]);

  const [LegalRequirements] = useState([
    "Comply with a legal obligation",
    "Protect and defend the rights or property of the Company",
    "Prevent or investigate possible wrongdoing in connection with the Service",
    "Protect the personal safety of Users of the Service or the public",
    "Protect against legal liability",
  ]);
  return (
    <div className="m-auto w-full max-w-[1240px] py-[50px] max_xl:max-w-[940px] max_md:px-[50px] max_md:py-[30px] max_sm:px-[20px] max_sm:py-[20px]">
      <div className="flex gap-[20px]">
        <div className="flex items-center justify-center">
          <img draggable="false" src={note} alt="" />
        </div>
        <div className="flex max-w-[584px] items-center max_md:w-full">
          <div className="w-full">
            <div className="text-[26px] font-bold text-duskwood max_md:mt-[25px] max_md:text-[18px] max_md:leading-[32px]">
              Privacy Policy for iGeek
            </div>
            <div className="text-MediumSize font-normal leading-[20px] text-stone-cold max_md:text-SmallSize max_md:leading-[20px]">
              Last Updated : May 15, 2023
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[70px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[50px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[30px]">
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </div>
      <div className="pt-[25px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[20px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[18px]">
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the Free Privacy Policy Generator.
      </div>

      {/* InterpretationDefinitions */}
      <InterpretationDefinitions />

      {/* CollectingPersonalData */}
      <CollectingPersonalData />

      {/* Use of Your Personal Data */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Use of Your Personal Data
          </div>
          <div>
            <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
              Definitions
            </div>
            <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
              The Company may use Personal Data for the following purposes:
            </div>
            <div className="pl-[15px]">
              {CompanyUsePersonalData.map((itm, index) => (
                <div
                  key={index}
                  className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
                >
                  <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                  <p>
                    <span className="font-semibold">{itm.title}</span>{" "}
                    {itm.description}
                  </p>
                </div>
              ))}
            </div>
            <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
              We may share Your personal information in the following
              situations:
            </div>
            <div className="pl-[15px]">
              {PersonalInformation.map((itm, index) => (
                <div
                  key={index}
                  className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
                >
                  <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                  <p>
                    <span className="font-semibold">{itm.title}</span>{" "}
                    {itm.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Retention of Your Personal Data */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Retention of Your Personal Data
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </div>
        </div>
      </div>

      {/* Transfer of Your Personal Data */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Transfer of Your Personal Data
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </div>
        </div>
      </div>

      {/* Delete Your Personal Data */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Delete Your Personal Data
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </div>
        </div>
      </div>

      {/* Disclosure of Your Personal Data */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Disclosure of Your Personal Data
          </div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Business Transactions
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Law enforcement
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </div>
          <div className="pt-[30px] text-[20px] font-semibold leading-[24px] text-bateau max_md:pt-[20px] max_md:text-[16px] max_md:leading-[20px] max_sm:pt-[10px]">
            Other legal requirements
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </div>
          <div className="pl-[15px]">
            {LegalRequirements.map((itm, index) => (
              <div
                key={index}
                className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]"
              >
                <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                <p>{itm}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Security of Your Personal Data */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Security of Your Personal Data
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </div>
        </div>
      </div>

      {/* Children's Privacy */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Children's Privacy
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </div>
        </div>
      </div>

      {/* Links to Other Websites */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Links to Other Websites
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </div>
        </div>
      </div>

      {/* Changes to this Privacy Policy */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Changes to this Privacy Policy
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div className="flex items-center pt-[50px] max_md:w-full max_md:pt-[30px] max_sm:pt-[20px]">
        <div className="w-full">
          <div className="text-[26px] font-bold text-duskwood max_md:text-[18px] max_md:leading-[32px]">
            Contact Us
          </div>
          <div className="pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </div>
          <div className="pl-[15px]">
            <div className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
              <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
              By email:{" "}
              <a
                className="flex items-center gap-[10px]"
                href="mailto:info@igeektech.com"
                onClick={() => "window.location=another.html"}
              >
                info@igeektech.com
              </a>
            </div>
            <div className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
              <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
              By visiting this page on our website:
              <a
                className="flex items-center gap-[10px]"
                href="https://igeektech.com/contact-us"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://igeektech.com/contact-us
              </a>
            </div>
            <div className="flex items-start gap-[10px] pt-[20px] text-MediumSize font-normal leading-[30px] text-[#252525] max_md:pt-[15px] max_md:text-SmallSize max_md:leading-[20px] max_sm:pt-[10px]">
              <span className="mt-[12px] h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
              By phone number: <a href="tel:+91 79908 37550">+91 7990837557</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
