import WebRoutes from "./Routes/WebRoutes";
import ScrollToTop from "./utils/ScrollTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <WebRoutes />
    </div>
  );
}

export default App;
