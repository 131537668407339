import React, {
  useState,
  Fragment,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import Marquee from "react-fast-marquee";
import HomeezIcon from "../../assets/common/svgs/testimonials/Homeez.svg";
import AllChopsIcon from "../../assets/common/svgs/testimonials/AllChopsLogo.png";
import RightFulIcon from "../../assets/common/svgs/testimonials/Rightful.png";
import StefanIcon from "../../assets/common/svgs/testimonials/stefan.svg";
import TulagramIcon from "../../assets/common/svgs/testimonials/tulagram.svg";
import GoBeautyIcon from "../../assets/common/svgs/testimonials/GoBeauty.svg";

import Christofer from "../../assets/common/images/christopher.png";
import Tyson from "../../assets/common/images/Tyson.png";
import Stefan from "../../assets/common/images/Stefan.png";
import ToniGluseski from "../../assets/common/images/toniGluseski.png";
import AmirAsor from "../../assets/common/images/amirAsor.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useOnClickOutside } from "../../utils/OutsideClick";

const Testimonial = () => {
  const outsideRef = useRef(null);
  const mainRef = useRef(null);

  const [testimonials] = useState([
    {
      name: "Tyson Lim",
      rating: "5",
      Icon: HomeezIcon,
      review:
        "Working with iGeek has been an amazing journey. They are very professional and experienced. Their management is very responsive and responsible. They go the extra mile to deliver results to their clients. We look forward to working more with iGeek and will definitely recommend iGeek to all my friends and whoever has need tech solutions.",
    },
    {
      name: "Chris Dobbs",
      rating: "5",
      Icon: RightFulIcon,
      review:
        "Vatsal and his team continue to impress me every day over the 3 years we have worked together. They get into the details and are as obsessed with quality and an optimum UI experience as I am. They iterate and work on a design until its perfect and as a result we have received lots of industry praise about the design and UI of our platform. I recommend them to anyone looking for a quality offshore solution for their platform.",
    },
    {
      name: "Eugene Chia",
      rating: "5",
      Icon: HomeezIcon,
      review:
        "Me and my team really enjoyed working with iGeek, they are superb in their development works and are very sincere, and do what they promised to even though some time those task assigned to them are quite difficult, yet they managed to do the impossible. I would love to work with them in the near future whenever possible.",
    },
    {
      name: "Femi Balogun",
      rating: "5",
      Icon: AllChopsIcon,
      review:
        "Very knowledgeable and skilled group. Always willing to give their time to provide free consultation and help resolve issues on other projects that are not even theirs. Enjoyed working with the group",
    },
    {
      name: "Stefan Wagner",
      rating: "5",
      Icon: StefanIcon,
      review:
        "Kiran was supporting us for almost two years on the project. He became a valuable team member and was very responsive and disciplined to stick to guidelines and processes, while also willing to improve skills over time. Can really recommend working with him and iGeek.",
    },
    {
      name: "Robert Dias",
      rating: "5",
      Icon: TulagramIcon,
      review:
        "iGeek did a very nice coding job for me.  They also did some intelligent design and suggestions and walked me thru the steps were I needed, as I only had a medium experience with all things IT.  I would highly recommend them 100% !!",
    },
    {
      name: "Marsh Rodriguez",
      rating: "5",
      Icon: GoBeautyIcon,
      review:
        "I had the pleasure of working with iGeek and was thoroughly impressed with their IT services. Their team was professional, communicative, and provided customized solutions that perfectly fit our needs. They exceeded our expectations with their exceptional technical skills and knowledge. I highly recommend iGeek to anyone looking for a reliable IT partner.",
    },
    {
      name: "Bruno Falcão",
      rating: "5",
      Icon: null,
      review:
        "All good, work was very well executed and they are always looking to help after !",
    },
    {
      name: "Toni Gluseski",
      rating: "5",
      Icon: ToniGluseski,
      review:
        "Ethika Group was able to work closely with the iGeekTech team to complete our website in time for its scheduled launch. Most importantly the iGeekTech team listened and where necessary involved other team members with the right skills and the right time. We were very pleased with the the final outcome and acknowledge all the hard work put in by everyone at iGeekTech. We continue to use the iGeekTech team to maintain and update the Ethika Group website.",
    },
    {
      name: "Amir Asor",
      rating: "5",
      Icon: AmirAsor,
      review:
        "Working with Kiran Gadhiya from iGeek has been an exceptional experience. He developed GoAlgo, a unique graphic coding language application for our tangible robot, demonstrating remarkable innovation and skill. Kiran's talent as a developer shone through, and we were thrilled with the results. The uniqueness of the app in the field sets it apart, and I'm grateful to have had the opportunity to collaborate with iGeek on this project. Their professionalism and ability to perform have left a lasting impression, and I highly recommend their services.",
    },
  ]);

  const [widthIframe, setWidthIframe] = useState("400px");
  const [heightIframe, setHeightIframe] = useState("250px");
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [widthWindow, setWidthWindow] = useState(0);
  useOnClickOutside(outsideRef, () => setIsVideoOpen(false));

  useLayoutEffect(() => {
    function updateSize() {
      setWidthWindow(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (widthWindow < 501) {
      setWidthIframe("300px");
      setHeightIframe("150px");
    } else {
      setWidthIframe("400px");
      setHeightIframe("250px");
    }
  }, [widthWindow]);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "" + "</span>";
    },
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (isVideoOpen) {
      mainRef.current.scrollIntoView();
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isVideoOpen]);

  const [youTubeVideos, setYouTubeVideos] = useState([
    {
      img: Stefan,
      src: "https://www.youtube.com/embed/BDVrrUyXQDM",
      title: "Stefan W. (Germany)",
    },
    {
      img: Christofer,
      src: "https://www.youtube.com/embed/T0U9EkvHcrk",
      title: "Christopher D. (Australia)",
    },
    {
      img: Tyson,
      src: "https://www.youtube.com/embed/lIFEmodM_AE",
      title: "Tyson L. (Singapore)",
    },
  ]);
  const [currentActive, setCurrentActive] = useState({});

  return (
    <>
      <div
        ref={mainRef}
        className="relative mb-[200px] w-full bg-morning-mist max_md:mb-[200px] max_sm:mb-[100px] "
      >
        <div className="flex flex-col items-center justify-center">
          <div className="PageTitle mb-[16px] mt-[70px] max_sm:mt-[50px]">
            Client Review
          </div>
          <div className="PageContent mb-[80px] w-full max-w-[935px] text-center max_md:px-[50px] max_sm:mb-[50px] max_sm:px-[20px]">
            Hear what our satisfied clients have to say about our IT services
            and solutions. We have always kept our clients on top of our
            priority and deliver quality and on-time solutions.
          </div>
        </div>
        <div className="pb-[200px] max_sm:pb-[140px]">
          <Marquee
            style={{
              alignItems: "start",
            }}
            speed={20}
            gradient={false}
            pauseOnHover={true}
          >
            {testimonials.map((itm, index) => (
              <div
                key={index}
                style={{
                  boxShadow:
                    "4.06049px 4.06049px 10.1512px rgba(0, 0, 0, 0.06)",
                }}
                className="mr-[10px] w-full max-w-[450px] rounded-md bg-white px-[13px] py-[16px] max_sm:max-w-[300px]"
              >
                <div className="flex justify-between">
                  <div className="flex flex-col text-RegularSize font-medium not-italic leading-4 max_sm:text-[13px]">
                    {itm.name}
                    <div className="mb-[15px] mt-[5px] flex">
                      {Array(Number(itm.rating))
                        .fill(undefined)
                        .map((i, index1) => (
                          <Fragment key={index1}>
                            <svg
                              width="10"
                              height="9"
                              viewBox="0 0 10 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.47388 8.40964L4.93937 6.52374L2.37947 8.3684L3.33308 5.29772L0.845703 3.34808L3.96762 3.33684L4.99013 0.288654L5.9655 3.35558L9.08379 3.41932L6.5674 5.32397L7.47388 8.40964Z"
                                fill="#FFC100"
                              />
                            </svg>
                          </Fragment>
                        ))}
                    </div>
                  </div>
                  <div className="flex items-start">
                    {itm.Icon && (
                      <img
                        draggable="false"
                        className="w-[60px]"
                        src={itm.Icon}
                        alt=""
                      />
                    )}
                  </div>
                </div>

                <div className="mt-0 text-SmallSize font-normal  not-italic leading-4 tracking-wider max_sm:text-[10px]">
                  {itm.review}
                </div>
              </div>
            ))}
          </Marquee>
        </div>

        <div
          className={`absolute ${
            widthWindow < 501
              ? "bottom-[-75px] h-[220px]"
              : "bottom-[-125px] h-[350px]"
          }  m-auto flex w-full justify-center`}
        >
          <Swiper
            slidesPerView={widthWindow > 1300 ? 3 : widthWindow > 1000 ? 2 : 1}
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            pagination={pagination}
            loop
            modules={[Pagination]}
            style={{
              maxWidth:
                widthWindow > 1300
                  ? "1280px"
                  : widthWindow < 500
                  ? "400px"
                  : "980px",
            }}
            className={
              widthWindow > 1300
                ? "swiper-pagination-web"
                : "swiper-pagination-tab"
            }
          >
            {youTubeVideos.map((itm, index) => (
              <SwiperSlide key={index} style={{ background: "transparent" }}>
                <div
                  style={{
                    borderRadius: "5px",
                    width: widthIframe,
                    height: heightIframe,
                    cursor: "pointer",
                    background: "grey",
                  }}
                  className="relative overflow-hidden rounded-[10px] text-[20px] max_sm:text-[16px]"
                  onClick={() => {
                    setIsVideoOpen(true);
                    setCurrentActive(itm);
                  }}
                >
                  <img className="" src={itm.img} alt="" />
                  <div className="absolute top-0 bottom-0 flex h-full  w-full items-center justify-center text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      fill="currentColor"
                      className="bi bi-play"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                    </svg>
                  </div>
                  <div className="absolute bottom-0 w-full bg-[#00000080] py-[5px] text-[16px] text-white max_sm:text-[12px]">
                    {itm.title}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {isVideoOpen && (
          <div className="absolute top-0 bottom-0 z-50 flex h-[100vh] w-full items-center justify-center bg-[#00000080]">
            <div
              style={{
                width: widthWindow > 940 ? "900px" : "100%",
              }}
              className="px-[30px]"
              ref={outsideRef}
            >
              <iframe
                style={{
                  width: "100%",
                  height: "500px",
                }}
                src={currentActive.src}
                title={currentActive.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Testimonial;
